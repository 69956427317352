.main {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 100px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 100px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  justify-content: space-between;
  gap: 10px;
}

.version_info {
  font-size: 14px;
  color: #bbb;
  text-align: center;
  margin-bottom: 20px;
}

.about_item_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 60%;
  padding: 20px;
}

.page_title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.requirements_section {
  background: #2E2E3E;
  border: 1px solid #444;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 100%;
  padding: 20px;
  color: #F9F9F9;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.requirements_table {
  width: 100%;
  border-collapse: collapse;
}

.requirements_table td {
  padding: 12px;
  border-bottom: 1px solid #444;
  color: #EFF0F6;
  font-size: 16px;
}

.requirements_table td strong {
  color: #FFF;
}

.download_buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.download_btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 18px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background: #0820F4;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #203bf4;
  }

  &:disabled {
    background: #5c5c5c;
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.download_icon {
  width: 16px;
  height: 16px;
  fill: white;
}

@media (max-width: 768px) {
  .wrapper {
    max-width: 100%;
    padding: 0 15px;
  }

  .download_buttons {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .download_btn {
    width: 100%;
    justify-content: center;
  }
}
