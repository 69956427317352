.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  width: auto;
  max-width: 1200px;
  height: auto;
  padding: 20px 20px 0 20px;
  border: 0;
  border-radius: 8px;
}