.main {
    padding: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
}

.versionText {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}

.termsContent {
    width: 100%;
    max-width: 800px;
    font-size: 14px;
    line-height: 1.6;
    color: #ccc;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    white-space: normal;
}

.error {
    color: #d9534f;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
}
