.wrapper {
    display: flex;
    width: 100%;
    max-width: 1320px;
    justify-content: space-between;
    gap: 10px;

    padding-bottom: 50px;
}

.left {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}

.right img {
    margin: 10px;
}

.footer {
    width: 100%;
    padding-top: 20px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    display: flex;
    justify-content: center;
    color: #F8F9FA;
}

@media screen and (max-width: 1320px) {
    .wrapper {
        flex-direction: column-reverse;
        align-items: center;
        gap: 5px;
    }

    .left, .right {
        width: 100%;
        height: auto;
        justify-content: center;
        text-align: center;
    }
}

.no_decoration_text {
    color: #F8F9FA;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
