.main {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 200px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1320px;
    justify-content: space-between;
    gap: 10px;
}

.beta_notice_container {
    display: flex;
    padding: 8px 14px;
    align-items: center;
    gap: 7px;
    height: 42px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 50px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: #091523;
}

.beta {
    display: flex;
    width: 34px;
    height: 18px;
    padding: 10px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    background: #5162F9;
    color: #000;
    font-size: 10px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.001px;
}

.beta_description {
    color: #5162F9;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.002px;
}

.carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1320px;
    height: 340px;
}

.carousel_title {
    color: var(--secondary-10, var(--Text-Inverse-Mono, #FFF));
    font-size: 42px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
}

.carousel_desc {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 500;
    text-align: center;
    color: #EFF0F6;
}

.carousel_buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

.carousel_buttons_get_started,
.carousel_buttons_recommended {
    padding: 9px 17px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: #fff;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.carousel_buttons_get_started {
    background-color: #5162F9;
}

.carousel_buttons_recommended {
    background-color: #6E7191;
}

.content1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1320px;
    margin-top: 30px;
}

.content1_row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 20px;
}

.content1_item {
    width: 100%;
    max-width: 373px;
    color: #F9F9F9;
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 20px;
}

.content1_item_container {
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.content1_item img {
    margin: 0 0 48px;
    width: 100%;
    max-width: 350px;
}

.content1_item_desc {
    margin-top: 20px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
    text-align: center;
    color: #EFF0F6;
}

.content2 {
    display: flex;
    width: 100%;
    max-width: 1320px;
    margin-top: 180px;
    gap: 20px;
}

.content2_left,
.content2_right {
    width: 100%;
    max-width: 600px;
}

.content2_left {
    padding-right: 20px;
}

.content2_title {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 44px;
    font-weight: 700;
    color: #fff;
}

.content2_desc {
    font-size: 22px;
    line-height: 27px;
    font-weight: 400;
    color: #EFF0F6;
}

.content2_right img {
    width: 100%;
    height: auto;
}

@media (max-width: 1100px) {
    .content2 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content2_left,
    .content2_right {
        max-width: 100%;
        padding: 10px;
        box-sizing: border-box;
        margin: 0 auto;
        text-align: center;

        img {
            max-width: 900px;
        }
    }

    .content2_title {
        font-size: 30px;
    }

    .content2_desc {
        font-size: 18px;
    }
}

@media (max-width: 720px) {
    .main {
        padding-top: 100px;
    }

    .carousel_title {
        font-size: 32px;
    }

    .carousel_desc {
        font-size: 16px;
    }

    .content1_row {
        flex-direction: column;
        align-items: center;
    }

    .content1_item {
        font-size: 22px;
        max-width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }

    .content1_item_container {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .content1_item img {
        max-width: 100%;
        width: 100%;
    }

}
