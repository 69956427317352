.no_style_link {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
}

.header {
  position: fixed;
  width: 100%;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  background-color: #091523;
  z-index: 1000;
}

.desktop_message {
  top: 0;
  position: fixed;
  width: 100%;
  background-color: #7893ff;
  color: #000;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  z-index: 9999;
  display: block;
}

.wrapper {
  max-width: 1320px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 20px 20px 0;
}

.no_underline {
  text-decoration: none;
  flex-grow: 1;
}

.logo {
  display: flex;
  align-items: center;
  width: 172px;
  gap: 10px;
  cursor: pointer;
}

.logo_symbol {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0;
}

.menu_list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.menu_item {
  color: #f8f9fa;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 8px 16px;
  white-space: nowrap;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  flex-grow: 0;
}

.login_signout {
  color: var(--secondary-10, #fff);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hamburger_button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 24px;
  position: relative;
  z-index: 1001;
  margin-right: 10px;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    transition: all 0.3s ease;
    left: 0;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 11px;
  }

  span:nth-child(3) {
    top: 22px;
  }

  &.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 11px;
  }

  &.open span:nth-child(2) {
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 11px;
  }
}

.mobile_menu {
  display: none;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

@media (max-width: 1200px) {
  .hamburger_button {
    display: block;
  }

  .mobile_menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #091523;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;
  }

  .mobile_menu.menu_open {
    max-height: 500px;
  }
}

.mobile_login_section {
  display: none;
}

@media (max-width: 1200px) {
  .wrapper {
    padding: 10px;
  }

  .menu {
    display: none !important;
  }

  .login {
    display: none !important;
  }

  .hamburger_button {
    display: block;
    margin-right: 10px;
  }

  .mobile_menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #091523;
    display: none;
  }

  .mobile_menu.menu_open {
    display: block;
  }

  .mobile_menu .menu_list {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .mobile_menu .menu_item {
    margin: 0;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #f8f9fa;
  }

  .mobile_menu .menu_item:last-child {
    border-bottom: none;
  }

  .mobile_login_section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #f8f9fa;
  }

  .mobile_login_section .login_signout,
  .mobile_login_section .google_login {
    color: #f8f9fa;
    font-size: 16px;
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
}
