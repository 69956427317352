$bg-color: #091523;
$card-bg: #1e2a38;
$card-border: #2b3a4d;
$text-color: #ffffff;
$button-bg: #00aaff;
$button-hover-bg: #0088cc;

.container {
  background-color: $bg-color;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card {
  background-color: $card-bg;
  border: 1px solid $card-border;
  border-radius: 8px;
  padding: 40px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.intro {
  margin-bottom: 20px;
  text-align: center;

  h2 {
    color: $text-color;
    margin-bottom: 10px;
  }

  p {
    color: $text-color;
    font-size: 16px;
    line-height: 1.6;
    text-align: left;
  }
}

.content {
  color: $text-color;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;

  a {
    color: $button-bg;
    text-decoration: underline;

    &:hover {
      color: $button-hover-bg;
    }
  }
}

.checkboxContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;

  input {
    margin-right: 10px;
  }

  label {
    color: $text-color;
    font-size: 16px;
  }
}

.agreeButton {
  background-color: $button-bg;
  color: $text-color;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: $button-hover-bg;
  }
}

.error {
  color: #d9534f;
  text-align: center;
  margin-bottom: 20px;
}
