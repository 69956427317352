@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;400;500;700&display=swap');

* {
    font-family: 'Noto Sans', sans-serif;
    text-decoration: none;
}

body, html {
    background-color: #091523;
    margin: 0;
    padding: 0;
}

body {
    max-width: 100vw;
    overflow-x: hidden;
    user-select: none;
}
