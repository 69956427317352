.main {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 100px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  justify-content: space-between;
  gap: 10px;
}

.pricing {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  color: #EFF0F6;
}

.pricing_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.pricing_title {
  font-size: 42px;
  line-height: 57px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.pricing_desc {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  text-align: center;
}

/* Disclaimer Section Styles */
.disclaimer {
  background: linear-gradient(135deg, #1E1E2D 0%, #2e2e3e 100%);
  border: 1px solid #444;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  color: #F9F9F9;
  text-align: left;

  @media (max-width: 720px) {
    padding: 15px;
  }
}

.disclaimer_title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #ffffff;
}

.disclaimer_list {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #ccc;

  li {
    margin-bottom: 10px;
    line-height: 1.5;
    font-size: 16px;

    strong {
      font-weight: 600;
      margin-right: 5px;
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: #82aaff;
      }
    }
  }
}

.pricing_cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
}

.pricing_card {
  background-color: #1E1E2D;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 320px;
  text-align: center;
  color: #F9F9F9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-3px);
  }

  @media (max-width: 720px) {
    max-width: 90%;
    margin-bottom: 20px;
  }
}

.pricing_card_title {
  font-size: 26px;
  line-height: 31px;
  font-weight: 700;
  margin-bottom: 10px;

  @media (max-width: 720px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.pricing_card_price {
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  margin-bottom: 20px;

  @media (max-width: 720px) {
    font-size: 20px;
    line-height: 26px;
  }
}

.pricing_card_list {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;

  li {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 22px;
  }

  @media (max-width: 720px) {
    margin-bottom: 20px;
  }
}

.pricing_card_button {
  padding: 10px 20px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: #fff;
  background-color: #0820F4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  margin-top: auto;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #203bf4;
  }

  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 22px;
    padding: 8px 16px;
  }
}

.current_plan_button {
  @extend .pricing_card_button;
  background-color: #5c5c5c;
  cursor: default;
  pointer-events: none;
}

.manage_subscription_button {
  @extend .pricing_card_button;
  background-color: #4545aa;
  margin-top: 5px;

  &:hover {
    background-color: #5858cf;
  }
}

.quantity_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: auto;
}

.quantity_input {
  width: 60px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #2E2E3E;
  border: 1px solid #444;
  border-radius: 5px;
  text-align: center;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
    border-color: #0820F4;
  }
}
