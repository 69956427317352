.main{
    width: 100%;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;

    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    max-width: 1320px;
    justify-content: space-between;
    gap: 10px;
}

.signin{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 580px;
    margin-top: 100px;
}

.signin_symbol {
    img {
        width: 30px;
    }
}

.signin_title{
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.02em;
    margin-top: 30px;
}

.signin_desc{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #F8F9FA;
    margin-top: 20px;
    margin-bottom: 40px;
}

.signin_button {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    padding: 15px;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 6px;
    background: #5162F9;

    &:hover {
        background-color: #3b4bdc;
    }
}

.divider_with_text {
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    color: #212C39;
}

.divider_with_text::before,
.divider_with_text::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #212C39;
    margin: 0 10px;
}

.email_signin {
    display: flex;
    flex-direction: column;

    color: #fff;
    margin-top:30px;
    width: 100%;
}

.email_signin .name {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-top: 10px;

    width: 100%;
}

.email_signin .show {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: right;
    margin-top: 10px;

    color: rgba(102, 102, 102, 0.8);
    cursor: pointer;
}

.email_signin .password_desc {
    display: flex;
}

.email_signin .inputbox {
    color: #fff;
    background-color: #212C39;  /* disabled: transparent */
    width: 100%;
    height: 46px;
    border: 1px solid #212C39;
    border-radius: 12px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;

    padding: 5px;
    margin-top: 10px;
}

.email_signin .checkbox {
    margin-top: 30px;
}

.email_signin_button {
    width: 100%;
    height: 64px;
    border: 0;
    border-radius: 32px;
    background-color: #212C39;  /* disabled: rgba(8, 32, 244, 1); */
    margin-top: 30px;

    color: grey;  /* disabled: rgba(255, 255, 255, 1); */
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: center;

    cursor: pointer;
}

.email_signup_desc {
    margin: 30px 0 100px 0;
    text-align: center;
    color: rgba(108, 117, 125, 1);
}

.email_signup_desc a,
.email_signup_desc a:visited {
    color: rgba(8, 32, 244, 1);
    text-decoration: none;
}

.email_signup_desc a:hover,
.email_signup_desc a:focus {
    text-decoration: underline;
}

@media screen and (max-width: 1320px) {
    .wrapper {
        width: 100%;
    }
    .signin{
        width: 75%;
        max-width: 580px;
    }
}

.appDownloadNotice {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #555;

    a {
        color: #5162f9;
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}