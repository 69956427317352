.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .processing_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 550px;
    text-align: center;

    .processing_image {
      display: flex;
      justify-content: center;
      width: 30%;
    }

    .progress_container {
      display: flex;
      align-items: center;
      width: 100%;

      progress {
        flex-grow: 1;
      }

      .percentage {
        margin-left: 10px;
        color: var(--secondary-10, #FFF);
        text-align: center;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
  }

  .email_form_container {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 25px;
    box-sizing: border-box;

    border-radius: 4px;
    border: 1px solid #30363D;
    background: var(--third-dark_80, #1A232E);

    .email_desc {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }

    .email_input_container {
      position: relative;
      margin-top: 8px;

      .email_form {
        margin-top: 8px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.10);

        color: var(--third-50, #90959C);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */

        border: 0;
        padding: 10px;
        width: calc(100% - 20px);
      }

      .email_form_img {
        position: absolute;
        right: 10px; /* Adjust as needed */
        top: 50%;
        transform: translateY(-40%);
        width: 48px;
        cursor: pointer;
      }
    }
  }
}