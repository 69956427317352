$bg-color: #091523;
$card-bg: #1e2a38;
$card-border: #2b3a4d;
$text-color: #ffffff;
$button-bg: #00aaff;
$button-hover-bg: #0088cc;
$reject-btn-bg: #d9534f;
$reject-btn-hover-bg: #c9302c;
$border-radius: 10px;

.cookieBanner {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 500px;
  background-color: $card-bg;
  color: $text-color;
  padding: 20px;
  border-radius: $border-radius;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

.bannerContent {
  width: 100%;
}

h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: bold;
}

p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 15px;

  a {
    color: $button-bg;
    text-decoration: underline;

    &:hover {
      color: $button-hover-bg;
    }
  }
}

.checkboxContainer {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);

    input {
      margin-right: 10px;
      accent-color: $button-bg;
    }
  }

  .disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.cookieButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.cookieButton {
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 50%;
  font-weight: bold;
}

.cookieAcceptButton {
  background-color: $button-bg;
  color: $text-color;

  &:hover {
    background-color: $button-hover-bg;
  }
}

.cookieRejectButton {
  background-color: $reject-btn-bg;
  color: $text-color;

  &:hover {
    background-color: $reject-btn-hover-bg;
  }
}

@media (max-width: 480px) {
  .cookieBanner {
    width: 95%;
    padding: 15px;
  }

  .cookieButtonContainer {
    flex-direction: column;
    gap: 5px;
  }

  .cookieButton {
    width: 100%;
  }
}
