.container {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  cursor: ew-resize;
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.fadeIn .image {
  opacity: 1;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #091523;
  cursor: ew-resize;
}
