.wrapper {
  display: flex;
  width: 100%;
  padding-top: 20px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  color: var(--secondary-10, #FFF);

  flex-direction: column;
  max-width: 1320px;
  justify-content: space-between;
  gap: 10px;

  .title_wrapper {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px; /* 133.333% */
    }
  }

  .models_scroll_box {
    user-select: none;

    margin-top: 50px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #47505A #212C39;
    white-space: nowrap;

    padding-bottom: 30px;
    margin-bottom: 50px;

    &::-webkit-scrollbar {
      height: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #1A232E;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #90959C;
      border-radius: 6px;
    }

    .model_card {
      flex-shrink: 0;
      width: 350px;
      height: 310px;
      background-color: #111C44;
      border: 0;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 16px;

      display: flex;
      flex-direction: column;

      overflow: hidden;

      &:last-child {
        margin-right: 0;
      }

      .model_image {
        background-color: #2e3338;
        border-radius: 18px;
        overflow: hidden;
      }

      .model_name {
        color: var(--secondary-10, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.36px;
      }

      .model_owner {
        color: var(--primary-40, #A0A3BD);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
        margin-bottom: 5px;
      }
    }
  }

  .box_title_wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .box_title {
      color: var(--secondary-10, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.32px;
    }

    .box_title_option {
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 0;
      border-radius: 6px;
      background: var(--third-10, #212C39);

      color: var(--secondary-40, #F8F9FA);
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;

      img {
        margin-right: 5px;
      }
    }
  }

  .prompt_box, .negative_prompt_box {
    width: 100%;
    background-color: #1A232E;
    border: 1px solid #90959C;
    color: #FFF;
    box-sizing: border-box;
    resize: none;

    font-size: 20px;
  }

  .prompt_box {
    height: 180px;
    padding: 12px;

    border-radius: 4px;
    border: 1px solid #30363D;
    background: var(--third-dark_80, #1A232E);
  }

  .negative_prompt_box {
    height: 110px;
    padding: 12px;

    border-radius: 4px;
    border: 1px solid #30363D;
    background: var(--third-dark_80, #1A232E);
  }

  .generate_button {
    width: 487px;
    height: 52px;
    background-color: #0820F4;
    border: 0;
    margin-top: 20px;
    align-self: flex-end;
    cursor: pointer;
    box-sizing: border-box;

    border-radius: 4px;
    background: var(--primary-100, #0820F4);

    color: var(--secondary-10, #FFF);
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }

}
