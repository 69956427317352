.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .download_button {
    cursor: pointer;
    color: var(--secondary-10, #FFF);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */

    display: flex;
    width: 487px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 4px;
    background: var(--third-25, #47505A);
    border: 0;

    box-sizing: border-box;

    margin-top: 100px;
  }
}