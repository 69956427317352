.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 0 20px;
}

.left {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  justify-content: flex-start;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.right img {
  margin: 10px;
}

.footer {
  width: 100%;
  padding-top: 20px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: center;
  color: #F8F9FA;
}

.no_decoration_text {
  color: #F8F9FA;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
