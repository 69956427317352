.no_style_link {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
}

.header {
  width: 100%;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  margin-bottom: 30px;

  .desktop_message,
  .persistent_message {
    width: 100%;
    box-sizing: border-box;
    background-color: #7893ff;
    color: #000;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 700;
    display: block;
    z-index: 9999;
  }

  .wrapper {
    display: flex;
    width: 100%;
    gap: 10px;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;

    .no_underline {
      text-decoration: none;
      flex-grow: 1;

      .logo {
        display: flex;
        align-items: center;
        width: 172px;
        gap: 10px;
        cursor: pointer;
        justify-content: center;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      flex-grow: 0;

      .menu_list {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
      }

      .menu_item {
        color: #F8F9FA;

        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;

        margin: 8px 16px;
        white-space: nowrap;
      }
    }

    .login {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-weight: 500;

      flex-grow: 0;

      .login_signup {
        color: #ffffff;
        background: #0820F4;
      }

      .login a {
        cursor: pointer;
        text-decoration: none;
      }

      .login_signin,
      .login_signup {
        color: #0820F4;
        background: #F6F8FA;
        border: 0;
        padding: 7px 13px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: center;
        cursor: pointer;
      }

      .login_signup {
        color: #ffffff;
        background: #0820F4;
      }

      .login_signout {
        color: var(--secondary-10, #FFF);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.32px;

        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .avatar_wrapper {
        cursor: pointer;
        user-select: none;

        .avatar {
          width: 38px;
          height: 38px;

          border-radius: 50%;
          object-fit: cover;

          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    .hamburger_button,
    .mobile_menu,
    .mobile_login_section {
      display: none;
    }
  }
}
