.main {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 200px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  justify-content: space-between;
  gap: 10px;
}

.about_page_title {
  display: inline-flex;
  padding: 8px 14px;
  align-items: center;
  gap: 7px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: #091523;
  color: #5162F9;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.002px;
  width: 146px;
  text-align: center;
  justify-content: center;
}

.about_item_container {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  border-bottom: 1px solid #212C39;
}

.about_item_title {
  color: var(--secondary-10, #FFF);
  font-size: 42px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.84px;
  margin: 0 0 40px 0;
}

.about_item_description {
  color: var(--secondary-80, #EFF0F6);
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.48px;
}

.about_form_container {
  display: flex;
  flex-direction: column;
  padding: 0 0 30px 0;
}

.about_form_title {
  color: var(--secondary-10, #FFF);
  font-size: 42px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.84px;
  margin: 40px 0;
  padding-left: 200px;
}

form {
  display: flex;
  flex-direction: column;
  padding-left: 200px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #D9D9DC;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.form-group input,
.form-group textarea,
.form-group select {
  border-radius: 6px;
  background: #313743;
  width: 100%;
  padding: 10px;
  border: 0;
  box-sizing: border-box;
  font-size: 16px;
  color: #D9D9DC;
  font-weight: 300;
  line-height: normal;
}

.form-group textarea {
  height: 200px;
}

.form-group input[type='checkbox'] {
  width: auto;
  margin-right: 10px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.checkbox-group label {
  margin-left: 5px;
  font-weight: normal;
  color: #D9D9DC;
}

.about_button {
  color: var(--Text-Inverse-Mono, #FFF);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  padding: 15px;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
  border-radius: 6px;
  background: #5162F9;
}

@media (max-width: 1200px) {
  .main {
    padding-top: 100px;
  }

  .wrapper {
    max-width: 100%;
  }

  .about_page_title {
    width: auto;
    font-size: 14px;
    padding: 8px 10px;
  }

  .about_item_container {
    padding: 20px 10px;
  }

  .about_item_title {
    font-size: 28px;
    margin: 0 0 20px 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .about_item_description {
    font-size: 16px;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .about_form_container {
    padding: 0 10px 30px 10px;
  }

  .about_form_title {
    font-size: 28px;
    margin: 20px 0;
    padding-left: 10px;
  }

  form {
    padding-left: 10px;
    padding-right: 10px;
  }

  .form-group label {
    font-size: 12px;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    font-size: 14px;
  }

  .form-group textarea {
    height: 150px;
  }

  .checkbox-group {
    flex-wrap: nowrap;
  }

  .checkbox-group input[type='checkbox'] {
    flex-shrink: 0;
  }

  .checkbox-group label {
    margin-left: 5px;
    font-size: 14px;
    line-height: 1.4;
  }

  .about_button {
    font-size: 16px;
    padding: 10px;
  }
}
